const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6941951",
    space_js: "//yfcpsb.yuncsnet.com/site/ni/h_q_erq/resource/fr.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6941952",
    space_js: "//yfcpsb.yuncsnet.com/common/o/ji_rfs/source/rg_y.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6941953",
    space_js: "//yfcpsb.yuncsnet.com/production/p-kjsg/common/ts/production/h/c.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Banner
};
